body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}




@media only screen and (max-width: 600px) {
  .aboutsec{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      position: relative;}
  
      .aboutsec  h2{
          font-size: 24px;
          font-weight: 600;
      }
      .aboutsec div:nth-child(1) {
          background-color:#d5372c;
          width: 100%;
          color: #ffffff;
          padding: 20px 30px 50px 30px;
        }
        .aboutsec div:nth-child(1) p{
          color: #ffffff;
        }
        .aboutsec  div:nth-child(2) {
          background-color: #f2f1f6;
          width: 100%;
          color: #000000;
          padding: 20px 30px 50px 30px;
        }
        .aboutsec  div:nth-child(3) {
          background-color: #3c1ae8;
          width: 100%;
          color: #ffffff;
          padding: 20px 30px 50px 30px;
        }
  }
 


@media only screen and (min-width: 601px) {
  .aboutsec{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      position: relative;
    }
  
      .aboutsec h2{
          font-size: 24px;
          font-weight: 700;
      }
      .aboutsec div:nth-child(1) {
          background-color:#d5372c;
          width: 33.33333333%;
          color: #ffffff;
          padding: 20px 30px 50px 30px;
        }
        .aboutsec div:nth-child(1) p{
          color: #ffffff;
        }

        .aboutsec div:nth-child(1) h2{
          color: #ffffff;
        }
        .aboutsec div:nth-child(1) p{
          color: #ffffff;
        }
        .aboutsec div:nth-child(2) {
          background-color: #ffffff;
          width: 33.33333333%;
          color: #000000;
          padding: 20px 30px 50px 30px;
        }
        .aboutsec div:nth-child(3) {
          background-color: #3c1ae8;
          width: 33.33333333%;
          color: #ffffff;
          padding: 20px 30px 50px 30px;
        }
        .aboutsec div:nth-child(3) p{
          color: #ffffff;
        }
        .aboutsec div:nth-child(3) h2{
          color: #ffffff;
        }
  }
 

.button{
  background-color: #000000!important;
  color: #ffffff;
  border-radius: 0px;
  border: none;
  padding: 10px 15px;
  position: absolute;
  bottom: 10px;
  width: 150px;
}

.service-details-card{
    padding: 30px 30px 10px 30px;
    box-shadow: 0px 8px 30px #6a6a6a1a;
    background-color: #ffffff;
    display: flex;
    align-items: flex-start;
    border-left: 5px solid var(--color-primary);
    min-height: 200px;
}

.productdesc{
  background-color: #ffffff;
  padding: 20px 20px;
  box-shadow: 0px 5px 10px 0px rgb(0 0 0 / 20%);
  margin-bottom: 20px;
  border-radius: 2px;
}

.productdesc h5{
  color:#e00a0a;
  font-size: 20px;
  font-weight: 700;
}
.productdesc p{
  font-size: 14px;
  font-weight: 600;
}
.productdesc table th, td{
  font-size: 14px;
}
.mainlogo{
  width: 200px;
}


.service_box{
  background-color: #ffffff;
  box-shadow: 0px 5px 10px 0px rgb(0 0 0 / 15%);
  border-radius: 5px 5px 0px 0px;
  padding: 10px 0px 0px 0px;
  text-align: center;
  min-height: 170px;
  border-bottom: 2px solid #e00a0a;
}

.service_box img{
  width: 70px;
}

.service_box h5{
  font-size: 16px;
  font-weight: 700;
  padding: 20px 0px 5px 0px;
  text-align: center;
}


.industries_box{
  background-color: #ffffff;
  box-shadow: 0px 5px 10px 0px rgb(0 0 0 / 15%);
  border-radius: 5px 5px 0px 0px;
  padding: 0px 0px 10px 0px;
  text-align: center;
  min-height: 170px;
}

.industries_box img{
  width: 100%;
}

.industries_box h5{
  font-size: 20px;
  font-weight: 700;
  padding: 20px 0px 0px 0px;
  text-align: center;
}


.client-wrapper-one a img{
  width: 150px;
  padding: 0px 0px;
  margin:20px 10px;
}

.contact-bg{
  background-image: url('https://planetronix.com/assets/images/breadcrumb/contact-us.jpg');
}
.aviation-bg{
  background-image: url('https://planetronix.com/assets/images/breadcrumb/aviation-breadcrumb.jpg');
}
.military-defence-bg{
  background-image: url('https://planetronix.com/assets/images/breadcrumb/military-defence.jpg');
}
.railway-bg{
  background-image: url('https://planetronix.com/assets/images/breadcrumb/railway-breadcrumb.jpg');
}

@media only screen and (max-width: 600px) {
  .footer-one-single-wized .quick-link-inner{
    display: flex;
    justify-content: flex-start;
    align-items: start;
    gap: 0px;
  }
}

@media only screen and (min-width: 601px) {
  .footer-one-single-wized .quick-link-inner{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 80px;
  }
}

.bg_banner-three .eight {
  background-image: url('https://planetronix.com/assets/images/banner/engine-maintainance.jpg');
  padding-top: 400px;
}

.bg_banner-three .eight-2 {
  background-image: url('https://planetronix.com/assets/images/banner/engine-maintainance.jpg');
  padding-top: 400px;
}
.bg_banner-three .eight-3 {
  background-image: url('https://planetronix.com/assets/images/banner/engine-maintainance.jpg');
  padding-top: 400px;
}




  
.banner-one .swiper-wrapper .swiper-slide{
          background-image: url('https://planetronix.com/assets/images/banner/engine-maintainance.jpg');
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
      }
  
      .banner-one .swiper-wrapper .swiper-slide .two{
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url('https://planetronix.com/assets/images/banner/engine-maintainance.jpg');

      }

      .banner-one .swiper-wrapper .swiper-slide .three{
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url('https://planetronix.com/assets/images/banner/engine-maintainance.jpg');
      }

/* .underconstructionbg{
  background-image: url('https://planetronix.com/assets/images/underconstructionbg.jpg');
  background-repeat: no-repeat;
  min-height: 100vh;
} */




@media only screen and (max-width: 600px) {
  .underconstruction{
    margin: 50px 0px 100px 0px;   
  }

.underconstruction h1{
  font-size: 28px;
  line-height: 30px;
  color: #d5372c;
  margin-bottom: 0px;
  padding-bottom: 0px;
  text-align: center;
}
.underconstruction h2{
  margin-top: 0px;
  font-size: 23px;
  text-align: center;
}
.underconstruction h3{
  font-size: 38px;
  padding: 0px 0px 30px 0px;
  text-align: center;
} 
.underconstruction p{
margin: 0px;
font-size: 16px;
font-weight: 600;
}
.underconstruction p span{
font-weight: 600;
}
.underconstruction ul li{
list-style-type: disc;
}

}


@media only screen and (min-width: 601px) {
  .underconstruction{
    margin: 50px 0px 100px 0px;   
  }

  .underconstruction h1{
    font-size: 35px;
    line-height: 30px;
    color: #d5372c;
    margin-bottom: 0px;
    padding-bottom: 0px;
    text-align: center;
  }
  .underconstruction h2{
    margin-top: 0px;
    font-size: 28px;
    text-align: center;
  }
  .underconstruction h3{
    font-size: 45px;
    padding: 0px 0px 30px 0px;
    text-align: center;
  } 
  
.underconstruction p{
  margin: 0px;
  font-size: 18px;
  font-weight: 600;
  }
  
  .underconstruction p span{
  font-weight: 600;
  }
  .underconstruction ul li{
  list-style-type: disc;
  }
  
  }